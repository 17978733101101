import * as React from "react"
import Layout from "../components/common/layout"
import Title from "../components/title"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import SubMenu from "../components/common/sub-menu"
import FourthCard from "../components/fourth-card"
const Generic = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  return (
    <Layout>
      <Helmet
          title='Post detail'
        />
      <SubMenu data={subMenu.data[0]} />
       <div className="generic-content">
            <div className="container">
                <div className="row heading-row">
                    <div className="col-lg-8">
                    <h1 className="page-title">Healthy Eating Your Whole Family Can Enjoy</h1>
                        <div className="date">
                            Posted on Sunday, November 14, 2022
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between" >
                    <div className="col-lg-8">
                        
                        <div className="content">
                            <p>
                                <img src="/images/generic-1.jpg" />
                            </p>
                            <h2>H2 section heading</h2>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                            <p><strong>Example bold formatting</strong></p>
                            <p><a href="">Example link formatting</a></p>
                            <hr/>
                            <h3>H3 section heading</h3>
                            <p>
                            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>
                            <blockquote>
                            “This is a sample pull quote with specific formating to stand out within the standard article blocks.”
                            </blockquote>
                            <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan.</p>
                            <ul>
                                <li>This is a bullet point example</li>
                                <li>This is a bullet point example</li>
                                <li>This is a bullet point example</li>
                            </ul>
                            <ol>
                                <li>This is a ordered list example</li>
                                <li>This is a ordered list example</li>
                                <li>This is a ordered list example</li>
                            </ol>
                            <figure>
                                <img src="/images/generic-2.jpg" />
                                <figcaption>
                                Caption / copyright for the photo
                                </figcaption>
                            </figure>
                            <h3>H3 sub section heading</h3>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                            <hr/>
                        </div>
                        <div className="source">
                            <h3>Sources</h3>
                            <ul className="nav flex-column">
                                <li>
                                    <a href="foodstandards.gov.au" target={'_blank'}>foodstandards.gov.au</a>
                                </li>
                                <li>
                                    <a href="examplelink.com.au" target={'_blank'}>examplelink.com.au</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="sidebar">
                            <FourthCard data={fourthCard.data[0]} background="bg-white" halfColMobile={true} vertical={true}></FourthCard>
                        </div>
                    </div>
                </div>
            </div>
       </div>
        
    </Layout>
  )
} 

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["blog-category-sub"]}}) {
      nodes {
        jsonName
        data {
          block
            data {
              sub_menu{
                title
                links {
                  id
                  text
                  link
                  active
                }
              }
              cards {
                id
                title
                image
                link
              }
              title_data {
                title
                content
                links{
                  id
                  text
                  url
                }
              }
            }
        }
      }
    }
  }
`
export default Generic
